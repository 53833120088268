import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const HrDailyNoteRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (personId, date) => {
    const params = {
      person_id: personId,
      date: date,
    }
    return api.auth(wpAuth).get(`/api/hr/daily-notes/`, params)
  },
  mutations: {
    create: {
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/hr/daily-notes/`, data)
      },
      updater: (state, result) => ({ ...state, data: [result] }),
    },
    update: {
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/hr/daily-notes/${id}/`, data)
      },
      updater: (state, result) => ({ ...state, data: [result] }),
    },
  },
})

export function useHrDailyNotes(personId, date) {
  return useRunRj(HrDailyNoteRj, [personId, date])
}
