import classNames from "classnames"
import { get, orderBy, sumBy } from "lodash"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { BsChevronLeft, BsChevronRight, BsPencilFill, BsX } from "react-icons/bs"
import { Alert } from "reactstrap"
import S from "../style.module.scss"

export function Detail({
  date,
  allocs,
  onDateChange,
  onClose,
  canEdit,
  onEdit,
  displayResourceName = false,
  presets,
  onPresetSelect,
  notes,
}) {
  const { t } = useTranslation()

  // eslint-disable-next-line no-unused-vars
  const { alerts: dateAlerts, work_day: workDay, allocations } = get(allocs, date.format("YYYY-MM-DD"), {
    allocations: [],
    alerts: [],
    work_day: true,
  })
  const isFilled = allocations.length > 0
  const hasAlerts = dateAlerts.length > 0
  const sortedAllocations = orderBy(allocations, ["kind_group", "kind_order"], ["asc", "asc"])

  return (
    <div className="px-8 py-7 border border-primary-30 rounded shadow h-100">
      <div className={classNames(S["detail-navi-grid"], "mb-8")}>
        <BsX className="pointer text-primary" onClick={onClose} style={{ fontSize: 24 }} />
        <div className="text-center text-capitalize text-primary">{date.format("MMMM - YYYY")}</div>
      </div>
      <div className={classNames(S["detail-navi-grid"], "mb-8")}>
        <BsChevronLeft
          className="pointer text-primary"
          onClick={() => {
            onDateChange(date.subtract(1, "day"))
          }}
        />
        <div
          className={classNames("d-flex flex-row align-items-center justify-content-center", {
            "text-primary": isFilled && !hasAlerts,
            "text-danger": hasAlerts,
          })}
        >
          <div className={classNames("mr-3", S["calendar-cell-day"])}>{date.get("date").toString().padStart(2, "0")}</div>
          <div className="text-capitalize">{date.format("dddd")}</div>
        </div>
        <BsChevronRight
          className="pointer text-primary"
          onClick={() => {
            onDateChange(date.add(1, "day"))
          }}
        />
      </div>
      {hasAlerts && (
        <div className="mb-8">
          {dateAlerts.map((alert, i) => {
            return (
              <Alert color="danger" className="py-2 px-3" key={i}>
                {alert}
              </Alert>
            )
          })}
        </div>
      )}
      {isFilled && (
        <div className="mb-6">
          <p className="mt-0 mb-7 font-weight-bold">{t("hr_workflow_new.current_allocations")}</p>
          {sortedAllocations.map((allocation, i) => {
            return (
              <Fragment key={allocation.id}>
                <div className="rounded bg-light p-3 d-flex flex-row justify-content-between align-items-center">
                  <span>
                    {displayResourceName && `[${allocation.profile_name}]: `}
                    {allocation.kind_verbose}
                  </span>
                  <div
                    className={S["calendar-badge"]}
                    style={{
                      backgroundColor: allocation.kind_color,
                      color: allocation.kind_text_color,
                    }}
                  >
                    {allocation.hours}h
                  </div>
                </div>
                {i < sortedAllocations.length - 1 && <hr className="my-4" />}
              </Fragment>
            )
          })}
          <div className="mt-7 d-flex flex-row justify-content-between align-items-center">
            <span>{t("hr_workflow_new.total")}</span>
            <div>{sumBy(sortedAllocations, "hours")}h</div>
          </div>
        </div>
      )}
      {!!notes && (
        <div className="mb-8">
          <p className="mt-0 mb-3 font-weight-bold">{t("hr_workflow_new.notes")}</p>
          <div className="px-2">{notes}</div>
        </div>
      )}
      {canEdit && (
        <div className="d-flex flex-row justify-content-center align-items-center ">
          <div
            className="rounded-circle bg-primary d-flex flex-column justify-content-center align-items-center pointer"
            style={{ width: 44, height: 44 }}
            onClick={() => {
              onEdit(date)
            }}
          >
            <BsPencilFill className="text-white" />
          </div>
        </div>
      )}
      {presets?.length > 0 && (
        <>
          <hr className="my-8" />{" "}
          <div>
            <div>
              <b>{t("hr_workflow_new.presets")}</b>
            </div>
            <div className="d-flex flex-row flex-wrap mt-3" style={{ rowGap: 4, columnGap: 10 }}>
              {presets.map((preset) => {
                return (
                  <div
                    className={classNames(S["calendar-badge"], "pointer", "py-2 px-3")}
                    onClick={() => {
                      onPresetSelect(preset)
                    }}
                    style={{
                      backgroundColor: preset.kind_color,
                      color: preset.kind_text_color,
                    }}
                  >
                    {preset.kind_verbose} {preset.hours}h
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
