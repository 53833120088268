import { Field, Formik } from "formik"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import FieldCustomerSelector from "../../../components/fields/FieldCustomerSelector"
import FieldDropdown from "../../../components/fields/FieldDropdown"
import FieldFavorite from "../../../components/fields/FieldFavorite"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { ProjectContext } from "../../../context"
import useModalTrigger from "../../../hooks/useModalTrigger"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import LinkEstimateModal from "./LinkEstimateModal"
import ProjectTabBarContent from "../ProjectTabBarContent"
import { FiExternalLink } from "react-icons/fi"
import FormAutoSave from "../../../components/FormAutoSave"
import WpLink from "../../../components/WpLink"
import ImportTasksModal from "./ImportTasksModal"
import ImportBillsModal from "./ImportBillsModal"
import { FaTrashAlt } from "react-icons/fa"
import ConfirmUnlinkModal from "./ConfirmUnlinkModal"
import FieldYesNo from "../../../components/fields/FieldYesNo"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { TagCollection } from "../../../components/TagCollection"
import { AuditTrailModal } from "../AuditTrailModal"
import { BsPencil } from "react-icons/bs"

function shouldSubmit(values, prevValues) {
  const shouldSave = values.favorite !== prevValues.favorite
  return shouldSave
}

export default function ProjectInfoStatic() {
  const { t } = useTranslation(["translation", "tab", "action"])
  const history = useWpHistoryMethods()

  const [{ project }, { importEstimate, updateProject, importBills, writeAuditTrail }] = useContext(ProjectContext)

  const [linkEstimateModalState, linkEstimateModalActions] = useModalTrigger()
  const [importTasksModalState, importTasksModalActions] = useModalTrigger()
  const [importBillsModalState, importBillsModalActions] = useModalTrigger()
  const [unlinkEstimateModalState, unlinkEstimateModalActions] = useModalTrigger()
  const [auditTrailModalState, auditTrailModalActions] = useModalTrigger()

  const statusOptions = useMemo(() => {
    const opts = ["configuring", "open", "closed", "on_hold", "canceled"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:project_status." + opt),
    }))
  }, [t])

  const kindOptions = useMemo(() => {
    const opts = ["development", "maintenance", "commercial", "generic"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:project_kind." + opt),
    }))
  }, [t])

  return (
    <Layout>
      <div className="container pt-9 px-half-page pb-page-bottom ">
        {!project && <Loader />}
        {project && (
          <>
            <Formik
              initialValues={project}
              onSubmit={(values, actions) => {
                return updateProject.asPromise(project.id, {
                  favorite: values.favorite,
                })
              }}
              enableReinitialize
            >
              {(formik) => {
                return (
                  <form autoComplete="off">
                    <FormAutoSave shouldSubmit={shouldSubmit} debounceTime={1} />
                    <div className="d-flex flex-column px-page">
                      <h2 className="text-center text-uppercase mb-5 font-weight-semibold">{t("project_info.title")}</h2>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="flex-1 pr-5">
                          <Field
                            name="name"
                            label={t("field:project.name")}
                            placeholder={t("field:project.placeholder.name")}
                            component={FieldInput}
                            readOnly
                          />
                        </div>
                        <div className="flex-1 pl-5 d-flex flex-row">
                          <div style={{ flex: "1 1 0%" }} className="mr-3">
                            <Field
                              name="code"
                              label={t("field:project.code")}
                              placeholder={t("field:project.placeholder.code")}
                              component={FieldInput}
                              readOnly
                            />
                          </div>
                          <div style={{ flex: "0 0" }}>
                            <Field
                              name="favorite"
                              label={t("field:project.favorite")}
                              component={FieldFavorite}
                              containerClassName="justify-content-center align-items-center"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5">
                          <Field
                            name="customer"
                            label={t("field:project.customer_name")}
                            placeholder={t("field:project.placeholder.customer_name")}
                            component={FieldCustomerSelector}
                            caption={formik.values.customer_name}
                            controlClassName="px-4 py-3 border-radius-xl"
                            enableNone
                            readOnly
                          />
                        </div>
                        <div className="flex-1 pl-5 d-flex flex-row">
                          <div className="flex-1 pr-5">
                            <Field
                              name="status"
                              label={
                                <div className="d-flex justify-content-between">
                                  {t("field:project.status")}
                                  <BsPencil
                                    className="pointer"
                                    onClick={() => auditTrailModalActions.open({ field: "status" })}
                                  />
                                </div>
                              }
                              component={FieldDropdown}
                              options={statusOptions}
                              readOnly
                            />
                          </div>
                          <div className="flex-1 pl-5">
                            <Field
                              name="project_value"
                              label={t("field:project.money_amount")}
                              placeholder={t("field:project.placeholder.money_amount")}
                              component={FieldInput}
                              type="number"
                              min="0.00"
                              step="0.01"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5">
                          <Field
                            name="created_at"
                            label={t("field:project.created_at")}
                            component={FieldInput}
                            type="date"
                            readOnly
                          />
                        </div>
                        <div className="flex-1 px-5">
                          <Field
                            name="date_begin"
                            label={t("field:project.date_begin")}
                            component={FieldInput}
                            type="date"
                            readOnly
                          />
                        </div>
                        <div className="flex-1 px-5">
                          <Field
                            name="estimated_delivery_date"
                            label={
                              <div className="d-flex justify-content-between">
                                {t("field:project.estimated_delivery_date")}
                                <BsPencil
                                  className="pointer"
                                  onClick={() => auditTrailModalActions.open({ field: "estimated_delivery_date" })}
                                />
                              </div>
                            }
                            component={FieldInput}
                            type="date"
                            readOnly
                          />
                        </div>
                        <div className="flex-1 pl-5">
                          <Field
                            name="date_end"
                            label={t("field:project.date_end")}
                            component={FieldInput}
                            type="date"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 d-flex flex-row pr-5">
                          <div className="flex-1 pr-5">
                            <Field
                              name="support_period_days"
                              label={t("field:project.support_period_days")}
                              component={FieldInput}
                              readOnly
                            />
                          </div>
                          <div className="flex-1 pl-5">
                            <Field
                              name="support_period_end"
                              label={t("field:project.support_period_end")}
                              component={FieldInput}
                              type="date"
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="flex-1 pl-5 d-flex flex-row">
                          <div className="flex-1 pr-5">
                            <Field
                              name="kind"
                              disabled
                              label={t("field:project.kind")}
                              component={FieldDropdown}
                              options={kindOptions}
                            />
                          </div>
                          <div style={{ flex: "0 0" }} className="pr-5">
                            <Field
                              name="is_consumptive"
                              readOnly={true}
                              label={t("field:project.is_consumptive")}
                              component={FieldYesNo}
                            />
                          </div>
                          <div style={{ flex: "0 0" }}>
                            <Field
                              name="include_in_indicators"
                              readOnly={true}
                              label={t("field:project.include_in_indicators")}
                              component={FieldYesNo}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="material_link"
                            label={
                              <span className="d-inline-flex flex-row justify-content-start align-items-end">
                                {t("field:project.material_link")}
                                {formik.values.material_link && (
                                  <a href={formik.values.material_link} target="_blank" rel="noopener noreferrer">
                                    <FiExternalLink className="ml-2" size={18} />
                                  </a>
                                )}
                              </span>
                            }
                            placeholder={t("field:project.placeholder.material_link")}
                            component={FieldInput}
                            type="url"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="description"
                            label={t("field:project.description")}
                            placeholder={t("field:project.placeholder.description")}
                            component={FieldTextarea}
                            rows={5}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="notes"
                            label={t("field:project.notes")}
                            placeholder={t("field:project.placeholder.notes")}
                            component={FieldTextarea}
                            rows={5}
                            readOnly
                          />
                        </div>
                      </div>

                      <h2 className="text-center text-uppercase mt-8 mb-5 font-weight-semibold">{t("project_info.tags")}</h2>
                      <div className="text-center">
                        <TagCollection tags={project.tags_data} className="mr-2" />
                        {project.tags_data.length === 0 && <i>{t("project_info.no_tags")}</i>}
                      </div>
                    </div>
                    <div className="text-center mt-8">
                      <Button
                        onClick={() => {
                          history.push(`/projects/${project.id}/edit`)
                        }}
                      >
                        {t("action:edit")}
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
            <div className="my-8 border-bottom border-secondary" />
            <div className="mt-8 px-page">
              <h2 className="text-center text-uppercase mb-5 font-weight-semibold">{t("project_info.linked_estimates")}</h2>
              <div>
                <div className="text-right">
                  <Button
                    size="sm"
                    onClick={() => {
                      linkEstimateModalActions.open("dummy")
                    }}
                  >
                    <i>{t("project_info.link_estimates")}</i>
                  </Button>
                </div>
                {project.linked_estimates_data.map((estimate) => (
                  <div
                    key={estimate.id}
                    className="border-bottom border-separator p-4 d-flex flex-row justify-content-between align-items-center"
                  >
                    <div className="w-50">
                      <WpLink to={`/estimates/${estimate.id}`}>
                        <div>
                          <small>{estimate.code}</small>
                        </div>
                        <div>
                          <span>{estimate.title}</span>
                        </div>
                      </WpLink>
                    </div>
                    <div className="w-25">{parseFloat(estimate.price).toFixed(2) + " €"}</div>
                    <div className="w-25 text-right" style={{ whiteSpace: "nowrap" }}>
                      <Button
                        size="sm"
                        className="mr-2"
                        onClick={() => {
                          importTasksModalActions.open(estimate.id)
                        }}
                      >
                        <i>{t("project_info.generate_tasks")}</i>
                      </Button>
                      <Button
                        size="sm"
                        className="mr-2"
                        onClick={() => {
                          importBillsModalActions.open(estimate.id)
                        }}
                      >
                        <i>{t("project_info.generate_bills")}</i>
                      </Button>
                      <Button
                        size="sm"
                        className="mr-2"
                        color="danger"
                        onClick={() => {
                          unlinkEstimateModalActions.open(estimate)
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {linkEstimateModalState.value && (
          <LinkEstimateModal
            isOpen={linkEstimateModalState.isOpen}
            toggle={linkEstimateModalActions.toggle}
            onClosed={linkEstimateModalActions.onClose}
            save={(payload) => {
              return updateProject
                .onSuccess(() => {
                  linkEstimateModalActions.close()
                })
                .asPromise(project.id, {
                  linked_estimates: project.linked_estimates.concat([payload.estimate]),
                })
            }}
            project={project}
          ></LinkEstimateModal>
        )}
        {unlinkEstimateModalState.value && (
          <ConfirmUnlinkModal
            isOpen={unlinkEstimateModalState.isOpen}
            toggle={unlinkEstimateModalActions.toggle}
            onClosed={unlinkEstimateModalActions.onClose}
            onConfirm={() => {
              return updateProject
                .onSuccess(() => {
                  unlinkEstimateModalActions.close()
                })
                .asPromise(project.id, {
                  linked_estimates: project.linked_estimates.filter((est) => est !== unlinkEstimateModalState.value.id),
                })
            }}
            item={unlinkEstimateModalState.value.title}
          ></ConfirmUnlinkModal>
        )}
        {importTasksModalState.value && (
          <ImportTasksModal
            isOpen={importTasksModalState.isOpen}
            toggle={importTasksModalActions.toggle}
            onClosed={importTasksModalActions.onClose}
            save={(payload) => {
              return importEstimate
                .onSuccess(() => {
                  importTasksModalActions.close()
                })
                .asPromise(project.id, {
                  estimate: importTasksModalState.value,
                  tasks: payload.tasks,
                })
            }}
            project={project}
            estimateId={importTasksModalState.value}
          ></ImportTasksModal>
        )}
        {importBillsModalState.value && (
          <ImportBillsModal
            isOpen={importBillsModalState.isOpen}
            toggle={importBillsModalActions.toggle}
            onClosed={importBillsModalActions.onClose}
            onConfirm={() => {
              return importBills
                .onSuccess(() => {
                  importBillsModalActions.close()
                })
                .asPromise(project.id, {
                  estimate: importBillsModalState.value,
                })
            }}
          ></ImportBillsModal>
        )}
        {auditTrailModalState.value?.field === "status" && (
          <AuditTrailModal
            isOpen={auditTrailModalState.isOpen}
            toggle={auditTrailModalActions.close}
            onClosed={auditTrailModalActions.onClose}
            onSave={(values) => {
              auditTrailModalActions.close()
              writeAuditTrail(project.id, {
                field: "status",
                new_value: values.newValue,
                reason: values.reason,
                attachment: values.attachment,
              })
            }}
            initialValue={project.status}
            reasonRequired={(newValue) => ["on_hold", "canceled"].includes(newValue)}
            widget={
              <Field name="newValue" label={t("field:project.status")} component={FieldDropdown} options={statusOptions} />
            }
          />
        )}
        {auditTrailModalState.value?.field === "estimated_delivery_date" && (
          <AuditTrailModal
            isOpen={auditTrailModalState.isOpen}
            toggle={auditTrailModalActions.close}
            onClosed={auditTrailModalActions.onClose}
            onSave={(values) => {
              auditTrailModalActions.close()
              writeAuditTrail(project.id, {
                field: "estimated_delivery_date",
                new_value: values.newValue,
                reason: values.reason,
                attachment: values.attachment,
              })
            }}
            initialValue={project.estimated_delivery_date}
            widget={
              <Field name="newValue" label={t("field:project.estimated_delivery_date")} component={FieldInput} type="date" />
            }
            reasonOptions={[
              {
                value: t("enums:audit_trail_reason_internal.internal"),
                label: t("enums:audit_trail_reason_internal.internal"),
              },
              {
                value: t("enums:audit_trail_reason_internal.external"),
                label: t("enums:audit_trail_reason_internal.external"),
              },
            ]}
          />
        )}
      </div>
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}
