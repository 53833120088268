import { Field, FormikContext, useFormik } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { deps } from "react-rocketjump"
import Button from "../../../components/Button"
import FieldDropdown from "../../../components/fields/FieldDropdown"
import WpModal from "../../../components/WpModal/WpModal"
import { useEstimateTree } from "../../../hooks/estimateTree"
import { adaptForScenarios } from "../../Estimate/treeOperations"

const DepthOptions = [
  // { value: "0", label: "Unico task" },
  { value: "1", label: "Primo livello" },
  { value: "2", label: "Secondo livello" },
]

export default function ImportTasksModal({ isOpen, toggle, onClosed, save, project, estimateId }) {
  const { t } = useTranslation(["translation", "action"])

  // deps.maybe(null) -> turn off automatic running provided by useRunRj
  const [{ estimate }, { run }] = useEstimateTree(deps.maybe(estimateId))

  const formik = useFormik({
    initialValues: { scenario: -1, depth: "1" },
    onSubmit: (values, actions) => {
      const slicedEstimate =
        values.scenario >= 0 ? adaptForScenarios(estimate, [values.scenario]) : adaptForScenarios(estimate, [])
      const queue = [...slicedEstimate.task_tree],
        tasks = []
      const depth = parseInt(values.depth)
      while (queue.length > 0) {
        const task = queue.shift()
        const taskDepth = task.path.split(".").length
        if (taskDepth < depth) {
          tasks.push({
            task: task.id,
            cost: task.own_cost,
            price: task.price,
          })
          queue.push(...task.children)
        } else if (taskDepth === depth) {
          tasks.push({ task: task.id, cost: task.cost, price: task.price })
        }
      }
      if (tasks.length === 0) {
        actions.setErrors({
          scenario: t("project_info.estimate_link_modal.empty_scenario_error"),
        })
        actions.setSubmitting(false)
      } else {
        return save({ tasks: tasks })
      }
    },
  })

  useEffect(() => {
    if (formik.values.estimate) {
      run(formik.values.estimate)
    }
  }, [formik.values.estimate, run])

  const selectableScenarios = [
    { label: t("scenarios.base"), value: -1 },
    ...(estimate?.scenarios?.map((scenario) => ({
      value: scenario.id,
      label: scenario.name,
    })) ?? []),
  ]

  return (
    <WpModal isOpen={isOpen} toggle={toggle} onClosed={onClosed} title={t("project_info.link_estimates")}>
      <FormikContext.Provider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column mt-5">
            <Field
              label={t("project_info.estimate_link_modal.scenario_label")}
              name="scenario"
              component={FieldDropdown}
              options={selectableScenarios}
              disabled={!formik.values.estimate}
              className="px-3 py-2"
            />
          </div>
          <div className="d-flex flex-column mt-5">
            <Field
              label={t("project_info.estimate_link_modal.depth_modal")}
              name="depth"
              component={FieldDropdown}
              options={DepthOptions}
              disabled={!formik.values.estimate}
              className="px-3 py-2"
            />
          </div>
          <div className="text-right mt-5">
            <Button type="button" size="sm" color="none" onClick={() => toggle()} className="mr-4">
              {t("action:cancel")}
            </Button>
            <Button type="submit" size="sm">
              {t("action:save")}
            </Button>
          </div>
        </form>
      </FormikContext.Provider>
    </WpModal>
  )
}
