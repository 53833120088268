import React, { useCallback, useMemo, useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import classNames from "classnames"
import S from "./DropdownList.module.scss"
import Icon from "../Icon"
import Scrollbars from "react-custom-scrollbars"
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md"

function DropdownList(
  {
    value,
    caption: getLabelOrLabel,
    options,
    onChange,
    emptyMessage,
    className,
    containerClassName,
    style,
    capitalize = true,
    placeholder = null,
    onSearchChange,
    autoFilterOptions = true,
    autoFilterIgnoreCase = false,
    invalid = false,
    clearable = false,
    onClear,
    oneLine = false,
    disabled = false,
    itemWidth = undefined,
    readOnly = false,
    readOnlyPlaceholder = "",
    ...props
  },
  ref
) {
  const [dropdownOpen, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const toggle = () => {
    setOpen(!dropdownOpen)
  }

  const [selectedLabel, showingPlaceholder] = useMemo(() => {
    let _selectedLabel = null,
      _showingPlaceholder = false
    if (typeof getLabelOrLabel === "string") {
      _selectedLabel = getLabelOrLabel
    }
    if (typeof getLabelOrLabel === "function") {
      _selectedLabel = getLabelOrLabel(value)
    }
    if (_selectedLabel === null || _selectedLabel === undefined || _selectedLabel === "") {
      _selectedLabel = placeholder
      _showingPlaceholder = true
    }
    return [_selectedLabel, _showingPlaceholder]
  }, [getLabelOrLabel, placeholder, value])

  const handleSearch = useCallback(
    (value) => {
      setInputValue(value)
      if (typeof onSearchChange === "function") {
        onSearchChange(value)
      }
    },
    [onSearchChange]
  )

  const ownOptions = useMemo(() => {
    if (autoFilterOptions) {
      return options.filter((opt) => {
        if (autoFilterIgnoreCase) {
          return !inputValue || opt.label.toLowerCase().includes(inputValue.toLocaleLowerCase())
        }
        return !inputValue || opt.label.includes(inputValue)
      })
    }
    return options
  }, [autoFilterOptions, inputValue, options, autoFilterIgnoreCase])

  const multiple = Array.isArray(value)

  const isOptionActive = multiple ? (id) => value.includes(id) : (id) => id === value

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={containerClassName}
      disabled={disabled || readOnly}
      {...props}
    >
      <DropdownToggle
        style={style}
        caret={false}
        className={classNames(
          className,
          "px-3 py-2 d-flex flex-row align-items-center justify-content-between",
          S["dropdown"],
          {
            "text-capitalize": capitalize,
            [S["invalid"]]: invalid,
            [S["oneline"]]: oneLine,
            [S["disabled"]]: disabled,
            [S["readonly"]]: readOnly,
          }
        )}
      >
        <div
          ref={ref}
          className={classNames("flex-1 d-block text-left", S["dropdown-label"], S["wp-nowrap"], {
            [S["placeholder"]]: showingPlaceholder,
          })}
        >
          {readOnly && !selectedLabel ? readOnlyPlaceholder : selectedLabel}
        </div>
        <Icon name="arrow-down" className="text-dark ml-2" />
        {clearable && (
          <Icon
            name="times"
            width={16}
            height={16}
            className="text-dark ml-2"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (typeof onClear === "function") {
                onClear()
              }
            }}
          />
        )}
      </DropdownToggle>
      <DropdownMenu
        className="w-100 p-0"
        style={{
          boxShadow: "2px 2px 5px 1px rgba(51,51,51,0.5)",
          width: itemWidth,
          minWidth: itemWidth !== undefined ? itemWidth + 1 : undefined,
          maxWidth: "100%",
        }}
      >
        <div
          className="d-flex flex-row justify-content-between align-items-center border-bottom border-separator"
          style={{ boxShadow: "0px 1px 5px 0px rgba(51,51,51,0.4)" }}
        >
          <input
            className="flex-1 py-1 pl-4"
            style={{ border: "1px solid transparent", borderTopLeftRadius: 6 }}
            placeholder="Search..."
            type="text"
            value={inputValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Icon name="search" className="pr-3" />
        </div>
        <div className={S["dropdown-menu"]}>
          {ownOptions.length === 0 && (
            <DropdownItem className="px-4 py-1" disabled>
              {emptyMessage}
            </DropdownItem>
          )}
          {ownOptions.length > 0 && (
            <Scrollbars style={{ height: Math.min(27 * ownOptions.length, 150) }}>
              {ownOptions.map((r) => {
                const isActive = isOptionActive(r.value)
                return (
                  <DropdownItem
                    active={isActive}
                    disabled={!!r.disabled}
                    className={classNames(
                      {
                        "text-capitalize": capitalize,
                      },
                      S["dropdown-item"],
                      "px-4 py-1"
                    )}
                    key={r.value}
                    onClick={(e) => {
                      if (multiple) {
                        e.stopPropagation()
                        e.preventDefault()
                        if (isActive) {
                          onChange(value.filter((item) => item !== r.value))
                        } else {
                          onChange(value.concat([r.value]))
                        }
                      } else {
                        onChange(r.value)
                      }
                    }}
                    toggle={!multiple}
                  >
                    {multiple && !isActive && <MdOutlineCheckBoxOutlineBlank className="mr-2" />}
                    {multiple && isActive && <MdOutlineCheckBox className="mr-2" />}
                    {r.label}
                  </DropdownItem>
                )
              })}
            </Scrollbars>
          )}
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default React.forwardRef(DropdownList)
