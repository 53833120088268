import dayjs from "dayjs"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { useAuthUser } from "use-eazy-auth"
import Button from "../../components/Button"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal"
import HrResourceSelector from "../../components/HrResourceSelector"
import Layout from "../../components/Layout"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"
import { CAP, CAPLEVEL, useCapabilities } from "../../hooks/capabilities"
import {
  useHrAllocationKinds,
  useHrAllocationPresets,
  useHrAllocations,
  useHrAllocationsReportNew,
} from "../../hooks/useHrAllocations"
import { useHrResources } from "../../hooks/useHrResources"
import useModalTrigger from "../../hooks/useModalTrigger"
import { ManageAllocationsModal } from "./ManageAllocationsModal"
import { Calendar } from "./components/Calendar"
import { Detail } from "./components/Detail"
import { Legend } from "./components/Legend"
import { Navbar } from "./components/Navbar"
import { Navigator } from "./components/Navigator"
import { get } from "lodash"
import { useHrDailyNotes } from "../../hooks/useHrDailyNotes"
import { deps } from "react-rocketjump"

function HrWorkflowNewSafe({ hrResource }) {
  const { t, i18n } = useTranslation()
  const [, { hasCapability }] = useCapabilities()

  const today = dayjs()
  const [[month, year], setMonthAndYear] = useState([today.get("month") + 1, today.get("year")])
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedResourceId, setSelectedResourceId] = useState(hrResource.id)
  const [{ pending: reportPending }, { run: downloadReport }] = useHrAllocationsReportNew()

  const filters = useMemo(() => {
    if (selectedResourceId > 0) {
      return { person: selectedResourceId }
    }
    return {}
  }, [selectedResourceId])

  const [{ data: allocs }, { destroy, run: reload, bulk_create }] = useHrAllocations(year, month, filters)
  const [{ data: kinds }] = useHrAllocationKinds()
  const [{ data: presets }] = useHrAllocationPresets()

  const [allocModalState, allocModalActions] = useModalTrigger()
  const [allocDestroyModalState, allocDestroyModalActions] = useModalTrigger()

  const [{ data: dailyNotes, loading: loadingNotes }, { create, update }] = useHrDailyNotes(
    selectedResourceId,
    deps.maybe(selectedDate?.format("YYYY-MM-DD"))
  )

  console.log(dailyNotes)

  return (
    <Layout displayRawContent className="flex-1 d-flex flex-column justify-content-start align-items-stretch">
      <Navbar />
      <div className="pt-5 px-page pb-page-bottom flex-1 d-flex flex-column">
        <Row style={{ marginLeft: -4, marginRight: -4 }}>
          <Col sm={2} className="d-flex flex-row align-items-center px-2">
            <h1 className="text-primary font-weight-semibold">{t("hr_workflow_new.title")}</h1>
          </Col>
          <Col sm={10} className="px-2">
            <Navigator
              month={month}
              year={year}
              onChange={setMonthAndYear}
              isReportLoading={reportPending}
              downloadReport={() => {
                downloadReport(year, month)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4 flex-1" style={{ marginLeft: -4, marginRight: -4 }}>
          <Col sm={2} className="px-2">
            <div className="bg-light rounded p-4 h-100 d-flex flex-column justify-content-start align-items-stretch">
              {hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN) && (
                <div className="mb-10">
                  <HrResourceSelector
                    containerClassName="w-100"
                    idOrIds={selectedResourceId}
                    onChange={(res) => {
                      setSelectedResourceId(res)
                    }}
                    extraOptions={[{ value: -1, label: t("hr_workflow_new.all_resources") }]}
                  />
                </div>
              )}
              {kinds && <Legend kinds={kinds} allocs={allocs} />}
              <div className="flex-1" />
              {hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN) && (
                <Button
                  onClick={() => {
                    allocModalActions.open({
                      initialUserIds: [],
                      initialStartDate: dayjs().locale(i18n.language),
                      initialEndDate: dayjs().locale(i18n.language),
                      initialValue: [],
                      dateMode: "range",
                    })
                  }}
                >
                  {t("hr_workflow_new.bulk_add_allocations")}
                </Button>
              )}
            </div>
          </Col>
          <Col sm={selectedDate ? 7 : 10} className="px-2">
            <Calendar
              month={month}
              year={year}
              allocs={allocs}
              onDateSelect={setSelectedDate}
              aggregateAllocations={selectedResourceId === -1}
            />
          </Col>
          {selectedDate && (
            <Col sm={3} className="px-2">
              <Detail
                date={selectedDate}
                allocs={allocs}
                onDateChange={(newDate) => {
                  setSelectedDate(newDate)
                  setMonthAndYear([newDate.get("month") + 1, newDate.get("year")])
                }}
                onClose={() => {
                  setSelectedDate(null)
                }}
                canEdit={selectedResourceId !== -1 && !loadingNotes}
                onEdit={() => {
                  allocModalActions.open({
                    initialUserIds: selectedResourceId,
                    initialStartDate: selectedDate,
                    initialEndDate: selectedDate,
                    initialValue: allocs[selectedDate.format("YYYY-MM-DD")]?.allocations ?? [],
                    dateMode: "single",
                    notes: dailyNotes?.[0]?.person === selectedResourceId ? dailyNotes[0].notes : "",
                  })
                }}
                displayResourceName={selectedResourceId === -1}
                presets={presets}
                onPresetSelect={async (preset) => {
                  const { allocations: currentAllocs } = get(allocs, selectedDate.format("YYYY-MM-DD"), {
                    allocations: [],
                  })
                  await bulk_create.asPromise({
                    users: [selectedResourceId],
                    start_date: selectedDate.format("YYYY-MM-DD"),
                    end_date: selectedDate.format("YYYY-MM-DD"),
                    allocations: [
                      ...currentAllocs.filter((alloc) => alloc.kind !== preset.kind),
                      { kind: preset.kind, hours: preset.hours },
                    ],
                    include_vacations: true,
                  })
                  await reload.asPromise(year, month, filters)
                  allocModalActions.close()
                }}
                notes={dailyNotes?.[0]?.person === selectedResourceId ? dailyNotes[0].notes : ""}
              />
            </Col>
          )}
        </Row>
      </div>
      {allocModalState.value && (
        <ManageAllocationsModal
          kinds={kinds}
          isOpen={allocModalState.isOpen}
          initialUserIds={allocModalState.value.initialUserIds}
          initialStartDate={allocModalState.value.initialStartDate}
          initialEndDate={allocModalState.value.initialEndDate}
          initialValue={allocModalState.value.initialValue}
          dateMode={allocModalState.value.dateMode}
          notes={allocModalState.value.notes}
          onCancel={allocModalActions.close}
          onClosed={allocModalActions.onClose}
          onSubmit={async (result) => {
            const { notes, ...data } = result
            const awaits = []
            const notesId = dailyNotes?.[0]?.id
            if (notesId) {
              awaits.push(update.asPromise(notesId, { notes }))
            } else {
              awaits.push(create.asPromise({ person: selectedResourceId, date: selectedDate.format("YYYY-MM-DD"), notes }))
            }
            awaits.push(
              bulk_create.asPromise({
                users: Array.isArray(data.users) ? data.users : [data.users],
                start_date: data.startDate.format("YYYY-MM-DD"),
                end_date: data.endDate.format("YYYY-MM-DD"),
                allocations: data.allocations,
                include_vacations: data.includeVacations,
              })
            )
            await Promise.all(awaits)
            await reload.asPromise(year, month, filters)
            allocModalActions.close()
          }}
        />
      )}
      {allocDestroyModalState.value && (
        <ConfirmDeleteModal
          isOpen={allocDestroyModalState.isOpen}
          item={`${allocDestroyModalState.value.kind_verbose} ×${allocDestroyModalState.value.hours}`}
          toggle={allocDestroyModalActions.close}
          onClosed={allocDestroyModalActions.onClose}
          onConfirm={async () => {
            await destroy(allocDestroyModalState.value.id)
            await reload.asPromise(year, month, filters)
            allocDestroyModalActions.close()
          }}
        />
      )}
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

export function HrWorkflowNew() {
  const { user } = useAuthUser()
  const [, { hasCapability }] = useCapabilities()

  const hrResourceFilter = useMemo(() => {
    return { is_active: true }
  }, [])
  const [{ data: hrResources }] = useHrResources(hrResourceFilter)
  const personalResource = hrResources?.find((r) => r.profile === user.id)
  const isHrAdmin = hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN)

  if (personalResource) {
    return <HrWorkflowNewSafe hrResource={personalResource} />
  } else if (hrResources && !personalResource && isHrAdmin) {
    return <HrWorkflowNewSafe hrResource={hrResources[0]} />
  } else if (hrResources && !personalResource && !isHrAdmin) {
    return (
      <Layout>
        <Navbar />
        <div className="px-page">Accesso negato</div>
        <Layout.FirstLevelNavi>
          <TrackerLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <Navbar />
        <div className="px-page">Caricamento...</div>
        <Layout.FirstLevelNavi>
          <TrackerLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  }
}
