import classNames from "classnames"
import { get, identity, isNil } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useImperativeHandle, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"
import DropdownItem from "reactstrap/lib/DropdownItem"
import DropdownMenu from "reactstrap/lib/DropdownMenu"
import DropdownToggle from "reactstrap/lib/DropdownToggle"
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown"
import { useAuthUser } from "use-eazy-auth"
import { useProjectsList } from "../hooks/projects"
import useModalTrigger from "../hooks/useModalTrigger"
import useModalTriggerQuota from "../hooks/useModalTriggerQuota"
import ConfirmDeleteModal from "./ConfirmDeleteModal/ConfirmDeleteModal"
import CreateProjectModal from "./CreateProjectModal"
import { CustomerSelector } from "./CustomerSelector"
import Dropdown from "./Dropdown"
import { DropdownItemCreateInteraction } from "./DropdownItemCreateInteraction"
import Icon from "./Icon"
import Loader from "./Loader/Loader"
import LongBorder from "./LongBorder"
import OrgUserSelector from "./OrgUserSelector/OrgUserSelector"
import Pagination from "./Pagination/Pagination"
import Separator from "./Separator"
import Table from "./Table"
import { TagCollection } from "./TagCollection"
import WpInput from "./WpInput"
import WpLink from "./WpLink"

function ProjectsListRef({ defaultFilter }, ref) {
  const { t } = useTranslation(["translation", "field", "enums", "action"])
  const { user } = useAuthUser()

  let defaultStatus = get(user.flags, "project_filter_status_default", "favorite")

  const [
    { view, code, title, ordering, kind, teamMember, customer, is_consumptive },
    setQueryParams,
    {
      page: debPage,
      view: debView,
      code: debCode,
      title: debTitle,
      kind: debKind,
      ordering: debOrdering,
      teamMember: debTeamMember,
      customer: debCustomer,
      is_consumptive: debIsConsumptive,
    },
    setDebouncedQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    view: {
      encode: identity,
      decode: (strValue) => strValue || defaultStatus,
    },
    kind: {
      encode: identity,
      decode: (strValue) => strValue || "all",
    },
    ordering: {
      decode: (v) => v || "id",
      encode: identity,
    },
    title: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    code: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    teamMember: {
      encode: (value) => (value || "").toString(),
      decode: (rawvalue) => (!!rawvalue ? parseInt(rawvalue, 10) : undefined),
    },
    customer: {
      encode: (value) => (value || "").toString(),
      decode: (rawvalue) => (!!rawvalue ? parseInt(rawvalue, 10) : undefined),
    },
    is_consumptive: {
      encode: (value) => (value || "").toString(),
      decode: (rawvalue) => (!!rawvalue ? rawvalue : "all"),
    },
  })

  const filters = useMemo(() => {
    const fx = {
      page: debPage,
      code: debCode,
      name: debTitle,
      ordering: debOrdering,
      kind: debKind,
      team_member: debTeamMember,
      customer: debCustomer,
    }
    if (debView === "favorite") {
      fx.favorite = true
      fx.status = undefined
    }
    if (debView === "open") {
      fx.favorite = false
      fx.status = "open"
    }
    if (debView === "closed") {
      fx.favorite = false
      fx.status = "closed"
    }
    if (debView === "on_hold") {
      fx.favorite = false
      fx.status = "on_hold"
    }
    if (debView === "configuring") {
      fx.favorite = false
      fx.status = "configuring"
    }
    if (debView === "all") {
      fx.favorite = false
    }
    if (debKind === "all") {
      delete fx.kind
    }
    if (debIsConsumptive !== "all") {
      fx.is_consumptive = debIsConsumptive
    }
    return { ...fx, ...defaultFilter }
  }, [debPage, debCode, debTitle, debOrdering, debKind, debView, defaultFilter, debTeamMember, debCustomer, debIsConsumptive])

  const [createModal, createModalActions] = useModalTriggerQuota()
  const [deleteModal, deleteModalActions] = useModalTrigger()

  const [{ projects, count, pageCount, hasNext, hasPrev, loading }, { remove, create, update, run: reload }] = useProjectsList(
    filters
  )

  const columns = useMemo(
    () => [
      {
        label: t("field:project.code"),
        name: "code",
        size: 5,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              <p className={classNames("prevent-overflow", "m-0")}>
                <WpLink to={`/projects/${datum.id}`}>
                  <small>{selectedDatum}</small>
                  <br />
                  <span>{datum.name}</span>
                </WpLink>
              </p>
              <div>
                <TagCollection tags={datum.tags_data} className="mr-2 my-1" />
              </div>
            </div>
          )
        },
      },
      {
        label: t("field:project.customer_name"),
        name: "customer_name",
        orderingField: "customer",
        size: 4,
      },
      {
        label: t("field:project.money_amount"),
        name: "project_value",
        orderingField: "project_value",
        render: (selectedDatum, colName, datum) => {
          return <div className="text-right flex-1">{selectedDatum ? parseFloat(selectedDatum).toFixed(2) + " €" : ""}</div>
        },
        size: 2,
      },
      {
        label: t("field:project.total_cost"),
        name: "cost",
        orderingField: "cost",
        render: (selectedDatum, colName, datum) => {
          return <div className="text-right flex-1">{selectedDatum ? parseFloat(selectedDatum).toFixed(2) + " €" : ""}</div>
        },
        size: 2,
      },
      {
        label: t("field:project.margin"),
        name: "margin",
        orderingField: "margin",
        render: (selectedDatum, colName, datum) => {
          return <div className="text-right flex-1">{selectedDatum ? parseFloat(selectedDatum).toFixed(2) + " %" : ""}</div>
        },
        size: 2,
      },
      {
        label: t("field:project.delay"),
        name: "delay",
        orderingField: "delay_abstract",
        render: (selectedDatum, colName, datum) => {
          return <div className="text-right flex-1">{!isNil(datum.delay) ? datum.delay + " " + t("days") : ""}</div>
        },
        size: 2,
      },
      {
        label: t("field:project.status"),
        name: "status",
        size: 4,
        render: (selectedDatum, colName, datum) => {
          return (
            <div>
              <span>
                {t("enums:project_kind." + datum.kind)}
                {datum.is_consumptive ? " (consuntivo)" : ""}
              </span>
              <br />
              <span>{t("enums:project_status." + selectedDatum)}</span>
            </div>
          )
        },
      },
      {
        label: t("actions"),
        name: "actions",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              {datum.favorite && (
                <span
                  className="pointer"
                  onClick={() => {
                    update(datum.id, { favorite: false })
                  }}
                >
                  <Icon name="star-filled" title={t("action:remove_favorite")} className="pointer text-dark" />
                </span>
              )}
              {!datum.favorite && (
                <span
                  className="pointer"
                  onClick={() => {
                    update(datum.id, { favorite: true })
                  }}
                >
                  <Icon name="star" title={t("action:add_favorite")} className="pointer text-dark" />
                </span>
              )}
              <WpLink className={"action-icon-container-primary-hover"} to={`/projects/${datum.id}/edit`}>
                {!datum.readonly && <Icon name="edit" title={t("action:edit_estimate")} className="pointer text-dark" />}
                {datum.readonly && <Icon name="view" title={t("action:view_estimate")} className="pointer text-dark" />}
              </WpLink>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                    <DropdownItemCreateInteraction
                      disabled={!datum.customer}
                      initialValues={{
                        project: datum.id,
                        customer: datum.customer,
                      }}
                      lockedFields={["project"]}
                    />
                    <Separator className="my-0 mx-4 border-primary" />
                    <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                      <div
                        className="d-flex flex-row align-items-center mx-4"
                        onClick={() => {
                          deleteModalActions.open(datum)
                        }}
                      >
                        <Icon name="delete" className="pointer mr-4" />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [t, update, deleteModalActions]
  )

  useImperativeHandle(
    ref,
    () => ({
      triggerCreate: createModalActions.open,
    }),
    [createModalActions.open]
  )

  return (
    <>
      {projects === null && <Loader />}
      {projects !== null && (
        <>
          <div className="d-flex flex-row align-items-center justify-content-between mt-3 mb-5">
            <div className="d-flex flex-row justify-content-start" style={{ gap: 10 }}>
              <div className="d-flex flex-column justify-content-end align-items-start">
                <span>{t("project_list.filter_code")}</span>
                <WpInput
                  medium
                  placeholder={t("field:project.code")}
                  value={code}
                  style={{ width: 150 }}
                  onChange={(e) =>
                    setDebouncedQueryParams({
                      code: e.target.value,
                      page: 1,
                    })
                  }
                />
              </div>
              <div className="d-flex flex-column justify-content-end align-items-start">
                <span>{t("project_list.filter_name")}</span>
                <WpInput
                  medium
                  placeholder={t("field:project.name")}
                  style={{ width: 150 }}
                  value={title}
                  onChange={(e) =>
                    setDebouncedQueryParams({
                      title: e.target.value,
                      page: 1,
                    })
                  }
                  className="pl-3"
                />
              </div>
              <div className="d-flex flex-column justify-content-end align-items-start">
                <span>{t("estimate_list.filter_status")}</span>
                <Dropdown
                  value={view}
                  className="px-3 py-2"
                  options={[
                    {
                      value: "favorite",
                      label: t("enums:project_filter.favorite"),
                    },
                    {
                      value: "configuring",
                      label: t("enums:project_status.configuring"),
                    },
                    {
                      value: "open",
                      label: t("enums:project_status.open"),
                    },
                    {
                      value: "closed",
                      label: t("enums:project_status.closed"),
                    },
                    {
                      value: "on_hold",
                      label: t("enums:project_status.on_hold"),
                    },
                    { value: "all", label: t("enums:project_filter.all") },
                  ]}
                  onChange={(val) => {
                    setQueryParams({ view: val, page: 1 })
                  }}
                  itemWidth={120}
                  style={{ width: 120 }}
                />
              </div>
              <div className="d-flex flex-column justify-content-end align-items-start">
                <span>{t("project_list.filter_kind")}</span>
                <Dropdown
                  value={kind}
                  className="px-3 py-2"
                  options={[
                    {
                      value: "development",
                      label: t("enums:project_kind.development"),
                    },
                    {
                      value: "maintenance",
                      label: t("enums:project_kind.maintenance"),
                    },
                    {
                      value: "commercial",
                      label: t("enums:project_kind.commercial"),
                    },
                    {
                      value: "generic",
                      label: t("enums:project_kind.generic"),
                    },
                    { value: "all", label: t("enums:project_filter.all") },
                  ]}
                  onChange={(val) => {
                    setQueryParams({ kind: val, page: 1 })
                  }}
                  itemWidth={140}
                  style={{ width: 140 }}
                />
              </div>
              <div className="d-flex flex-column justify-content-end align-items-start">
                <span>{t("project_list.filter_team")}</span>
                <OrgUserSelector
                  clearable
                  itemWidth={200}
                  userId={teamMember}
                  onMemberChange={(arg) => {
                    setQueryParams({ teamMember: arg?.id ?? undefined, page: 1 })
                  }}
                  style={{ width: 200 }}
                  placeholder={t("project_list.filter_team_placeholder")}
                  useShortNames
                  useShortSelectors
                />
              </div>
              <div className="d-flex flex-column justify-content-end align-items-start">
                <span>{t("project_list.filter_customer")}</span>
                <CustomerSelector
                  clearable
                  itemWidth={200}
                  customerId={customer}
                  onChange={(arg) => {
                    console.log("ARG", arg)
                    setQueryParams({ customer: arg ?? undefined, page: 1 })
                  }}
                  style={{ width: 200 }}
                  placeholder={t("project_list.filter_customer_placeholder")}
                  className="px-3 py-2"
                />
              </div>
              <div className="d-flex flex-column justify-content-end align-items-start">
                <span>{t("project_list.filter_consumptive")}</span>
                <Dropdown
                  value={is_consumptive}
                  className="px-3 py-2"
                  options={[
                    { value: "all", label: t("enums:project_filter.all") },
                    {
                      value: "true",
                      label: t("enums:project_filter_consumptive.consumptive"),
                    },
                    {
                      value: "false",
                      label: t("enums:project_filter_consumptive.not_consumptive"),
                    },
                  ]}
                  onChange={(val) => {
                    setQueryParams({ is_consumptive: val, page: 1 })
                  }}
                  itemWidth={180}
                  style={{ width: 120 }}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end align-items-center text-nowrap">
              {loading && <Spinner size="sm" />}
              <span className="ml-2">
                {t("project_list.project_count", {
                  count: count,
                })}
              </span>
            </div>
          </div>

          {/*Something to show*/}
          {count !== 0 && (
            <>
              <LongBorder topBar="toolbar" />

              <Table
                use24
                columns={columns}
                data={projects}
                className="pb-8"
                highlightRows={[]}
                ordering={ordering}
                onOrderChange={(val) => {
                  setQueryParams({ ordering: val, page: 1 })
                }}
              />

              <Pagination
                current={debPage}
                pageCount={pageCount}
                goToPage={(page) => {
                  setQueryParams({ page })
                  window.scrollTo(0, 10)
                }}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            </>
          )}
        </>
      )}

      {createModal.value && (
        <CreateProjectModal
          isOpen={createModal.isOpen}
          toggle={createModalActions.toggle}
          onClosed={createModalActions.onClose}
          onSave={(data) => {
            create
              .onSuccess(() => {
                reload(filters)
                createModalActions.close()
              })
              .run(data)
          }}
        />
      )}

      {deleteModal.value && (
        <ConfirmDeleteModal
          toggle={deleteModalActions.toggle}
          isOpen={deleteModal.isOpen}
          onConfirm={() => {
            remove
              .onSuccess(() => {
                reload(filters)
                deleteModalActions.close()
              })
              .run(deleteModal.value.id)
          }}
          onClosed={deleteModalActions.onClose}
          item={deleteModal.value.name}
        />
      )}
    </>
  )
}

export const ProjectsList = React.forwardRef(ProjectsListRef)
